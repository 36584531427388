import SigninModal from './SigninModal'
import SignupModal from './SignupModal'

import img1 from "../imgs/cheatseatimg1.webp"

export default function LandingPage() {
    return (
        <div className="w-full grid md:grid-cols-2 place-items-center pt-10 pb-6 md:pt-48 md:pb-24">
            <div className='relative grid place-items-center'>
                <div className='absolute top-0 bottom-0 scale-y-[120%] bg-opacity-90 left-0 right-0 bg-[#fff] -z-10 blur-3xl'></div>
                <h1 className='text-xl md:text-xl max-w-3xl blur-none z-20 font-[550] text-center mt-4 mb-0 '>cheatseat course sniper</h1>
                <h1 className='text-4xl md:text-6xl max-w-3xl blur-none z-20 font-[550] text-center mt-4 mb-8'>The Best, Free McMaster Course Sniper</h1>
                <div className='grid grid-cols-2 gap-2 place-items-center'>
                    <button className="btn btn-neutral text-lg font-semibold" onClick={()=>(document.getElementById('signupModal') as HTMLDialogElement).showModal()}>Get Started*</button>
                    <button className="btn btn-outline bg-zinc-50 text-lg font-semibold" onClick={()=>(document.getElementById('signinModal') as HTMLDialogElement).showModal()}>Sign In</button>
                    <SigninModal />
                    <SignupModal />
                    <p className='col-span-2 text-sm text-center'>*cheatseat is completely free</p>
                </div>
            </div>
            <div className=' invisible w-0 md:p-2 md:visible md:w-full relative'>
                <div className='absolute top-0 bottom-0 scale-[100%] bg-opacity-30 left-0 right-0 bg-[#6a2aff] -z-10 blur-lg'>
                </div>
                <div className="w-full h-full border-white mockup-browser bg-zinc-200 border">
                    <div className="mockup-browser-toolbar">
                        <div className="input">https://cheatseat.ca</div>
                    </div>
                    <img className='md:h-full' src={img1} alt="Logo" />
                </div>
            </div>
            <div className='grid place-items-center md:invisible visible mx-4'>
                <p className='w-full text-lg'>
                    Get into any course, faster than anyone else,
                    and easier than before.
                </p>
            </div>
        </div>

    );
}
